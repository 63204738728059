import { Table } from 'flowbite-react';
import { MouseEventHandler } from 'react';
import { HiLockClosed, HiOutlineTrash, HiQrcode, HiZoomIn } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import ShrtExternalLink from '../../../../components/common/ShrtExternalLink';
import { Link as ApiLink } from '../../../../models/link';
import { formatDay } from '../../../../utils/DateUtils';

type ShrtLinkRowProps = {
  displayProtected?: boolean;
  displayQrCode?: boolean;
  link: ApiLink;
  onDeleteHandler?: (linkId: string) => void;
  opened?: boolean;
  pathname: string;
  showDetailsAction?: boolean;
};

export default function ShrtLinkRow({
  displayProtected = false,
  displayQrCode = false,
  link,
  onDeleteHandler,
  opened = true,
  pathname,
  showDetailsAction = false,
}: ShrtLinkRowProps) {
  const destination = `${pathname}${pathname.endsWith('/links') ? '' : '/links'}/${link.id}`;

  const handleOnDeleteLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
    onDeleteHandler && onDeleteHandler(link.id);
  };

  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 dark:text-white" key={link.id}>
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        <Link className="text-cyan-600 hover:underline dark:text-cyan-500" to={destination} state={{ link }}>
          {link.id}
        </Link>
      </Table.Cell>
      {displayQrCode && <Table.Cell>{link.qrCode && <HiQrcode className="mr-2 h-5 w-5" />}</Table.Cell>}
      {displayProtected && <Table.Cell>{link.protected && <HiLockClosed className="mr-2 h-5 w-5" />}</Table.Cell>}
      <Table.Cell>
        <ShrtExternalLink to={link.url} />
      </Table.Cell>
      <Table.Cell>
        <ShrtExternalLink to={link.redirectTo} />
      </Table.Cell>
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{opened ? link.opened : formatDay(link.created, 'll')}</Table.Cell>
      <Table.Cell>
        <Link className="text-red-600 hover:underline dark:text-red-500" onClick={handleOnDeleteLink} to="#">
          <HiOutlineTrash className=" mr-2 h-5 w-5" />
        </Link>
      </Table.Cell>
      {showDetailsAction && (
        <Table.Cell>
          <Link className="text-cyan-600 hover:underline dark:text-cyan-500" to={destination} state={{ link }}>
            <HiZoomIn className="mr-2 h-5 w-5" />
          </Link>
        </Table.Cell>
      )}
    </Table.Row>
  );
}
