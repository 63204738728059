import { TextInput, TextInputProps } from 'flowbite-react';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';

type ShrtTextInputProps<T> = TextInputProps & {
  defaultPlaceholder?: string;
  formikProps: FormikProps<T>;
  id: string;
  placeholderId?: string;
};

export default function ShrtTextInput({ id, defaultPlaceholder, placeholderId, formikProps, ...props }: ShrtTextInputProps<any>) {
  const intl = useIntl();
  const placeholder =
    placeholderId && defaultPlaceholder
      ? intl.formatMessage({
          id: placeholderId,
          defaultMessage: defaultPlaceholder,
        })
      : undefined;

  return (
    <TextInput
      color={formikProps.touched[id] && formikProps.errors[id] ? 'failure' : undefined}
      helperText={formikProps.touched[id] && formikProps.errors[id] ? <span className="font-medium">{formikProps.errors[id] as string}</span> : null}
      id={id}
      placeholder={placeholder}
      shadow
      {...props}
      {...formikProps.getFieldProps(id)}
    />
  );
}
