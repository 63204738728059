export const updateBillingInformation = `
  mutation UpdateMyBillingInformation($input: UpdateMyBillingInformationInput!) {
    updateMyBillingInformation(input: $input) {
      city
      company
      country
      name
      street
      zipCode
    }
  }
`;
