import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ShrtInfiniteQuery from '../../../../components/common/ShrtInfiniteQuery';
import ShrtSpinner from '../../../../components/common/Spinner';
import ShrtTable from '../../../../components/common/Table';
import { ApiKey } from '../../../../models/api-key';
import { Link as ApiLink } from '../../../../models/link';
import { apiKeysSelector, selectApiKey, selectedApiKeySelector } from '../../../../redux/reducers/api-keys/api-keys-reducer';
import { pricingPlanSelector } from '../../../../redux/reducers/user/user-reducer';
import { hasProtectedLinkFeature, hasQrCodeFeature } from '../../../../services/features.service';
import { getMyLinks } from '../../../../services/shortenify.service';
import ShrtLinkRow from '../table/ShrtLinkRow';

export default function ShrtApiKeyLinks() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [, , apiKeyParam] = location.pathname.split('/');
  const myApiKeys = useSelector(apiKeysSelector);
  const selectedApiKey = useSelector(selectedApiKeySelector) ?? null;
  const pricingPlan = useSelector(pricingPlanSelector) ?? null;
  const hasProtectedLink = pricingPlan ? hasProtectedLinkFeature(pricingPlan) : false;
  const hasQrCode = pricingPlan ? hasQrCodeFeature(pricingPlan) : false;

  useEffect(() => {
    const validateApiKey = async (apiKeys: ApiKey[]) => {
      const existingApiKey = apiKeys?.find((key) => key.id === apiKeyParam);
      if (existingApiKey?.id && selectedApiKey === null) {
        dispatch(selectApiKey(existingApiKey));
      }
    };
    if (!selectedApiKey) {
      validateApiKey(myApiKeys);
    }
  }, [apiKeyParam, dispatch, myApiKeys, selectedApiKey]);

  const handleOnDelete = (linkId: string) => {};

  // Case of a refresh on that page
  if (!selectedApiKey) {
    return <ShrtSpinner />;
  }

  return (
    selectedApiKey && (
      <ShrtInfiniteQuery
        apiKey={selectedApiKey.id}
        query={(after?: string) => getMyLinks(selectedApiKey.id, after)}
        queryKey={`links#${selectedApiKey.id}`}
        queryKeyResponse="getLinks"
      >
        {(items) => {
          return (
            <ShrtTable
              emptyDataDefaultLabel="No data"
              emptyDataLabel="common.empty_data"
              headLabels={[
                <FormattedMessage id="common.id" defaultMessage="ID" />,
                hasQrCode && <FormattedMessage id="common.qr_code" defaultMessage="QR code" />,
                hasProtectedLink && <FormattedMessage id="common.protected" defaultMessage="Protected" />,
                <FormattedMessage id="common.url" defaultMessage="URL" />,
                <FormattedMessage id="common.redirect_to" defaultMessage="Redirect to" />,
                <FormattedMessage id="common.opened" defaultMessage="Opened" />,
                <span className="sr-only">Delete</span>,
              ]}
              items={items}
              onCreateDefaultLabel="Create a short link"
              onCreateLabel="pages.dashboard.api_key_statistics.buttons.create_link"
              renderRow={(link: ApiLink) => (
                <ShrtLinkRow
                  key={link.id}
                  link={link}
                  displayProtected={hasProtectedLink}
                  displayQrCode={hasQrCode}
                  pathname={location.pathname}
                  onDeleteHandler={handleOnDelete}
                />
              )}
            />
          );
        }}
      </ShrtInfiniteQuery>
    )
  );
}
