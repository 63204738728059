import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ShrtSpinner from '../../components/common/Spinner';
import { useShrtMutation } from '../../hooks/useShrtMutation';
import { PricingPlan, User, UserBilling } from '../../models/user';
import { loadingMyInformationSelector, myInformationSelector } from '../../redux/reducers/user/user-reducer';
import { getPricingPlan } from '../../services/features.service';
import { updateMyBillingInformation } from '../../services/shortenify.service';
import ShrtFreeTierForm from './forms/free_tier';
import ShrtPricingPlanForm from './forms/plan';

const isUnderFreeTier = (user?: User) => user?.billing?.pricingPlan === PricingPlan.FreeTier;

export default function ShrtAccountBilling() {
  let [searchParams] = useSearchParams({
    plan: PricingPlan.Essential.toString(),
  });
  const pricingPlan = getPricingPlan(searchParams.get('plan') ?? '') ?? PricingPlan.Essential;
  const loadingMyInformation = useSelector(loadingMyInformationSelector);
  const user = useSelector(myInformationSelector);
  const freeTier = isUnderFreeTier(user);
  const updateMyInformationMutation = useShrtMutation('user', updateMyBillingInformation, 'updateMyBillingInformation');
  const portal = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL;
  const url = `${portal!}?prefilled_email=${user?.email}&client_reference_id=${user?.id}`;

  const handlePayment = (pricingPlan: PricingPlan) => {
    updateMyInformationMutation.mutate({
      pricingPlan: pricingPlan,
    });
    const link = process.env[`REACT_APP_STRIPE_${pricingPlan.toUpperCase()}_PLAN_LINK`];
    window.open(`${link!}?prefilled_email=${user?.email}&client_reference_id=${user!.id}`, '_blank', 'noopener,noreferrer');
  };

  const handleUpdate = async (billing: UserBilling) => {
    await updateMyInformationMutation.mutateAsync(billing);
  };

  if (loadingMyInformation || !user) {
    return <ShrtSpinner />;
  }

  if (freeTier) {
    return <ShrtFreeTierForm onProceedPayment={handlePayment} pricingPlan={pricingPlan} user={user} />;
  }
  return <ShrtPricingPlanForm billing={user.billing} onChangePlan={handleUpdate} pricingPlan={user.billing.pricingPlan} url={url} />;
}
