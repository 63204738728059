import { ConsoleLogger } from 'aws-amplify/utils';
import { Button, Label, Select, TextInput } from 'flowbite-react';
import { Formik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ShrtFormikTextInput from '../../../components/common/ShrtFormikTextInput';
import { PricingPlan, UserBilling } from '../../../models/user';
import { billingValidationSchema } from '../page_validation';

type ShrtPricingPlanFormProps = {
  billing?: UserBilling;
  onChangePlan: (values: any) => Promise<void>;
  pricingPlan: PricingPlan;
  url: string;
};

const logger = new ConsoleLogger('ShrtPricingPlanForm', process.env.REACT_APP_LOG_LEVEL);

export default function ShrtPricingPlanForm({ billing, onChangePlan, pricingPlan, url }: ShrtPricingPlanFormProps) {
  logger.info('current plan:', pricingPlan);
  const intl = useIntl();

  const handleOnSubmit = async (values: any) => {
    const { pricingPlan, ...others } = values;
    logger.info('billing information updated:', others);
    await onChangePlan(others);
  };

  return (
    <Formik
      initialValues={{
        city: billing?.city,
        company: billing?.company,
        country: billing?.country,
        name: billing?.name,
        pricingPlan: billing?.pricingPlan ?? pricingPlan,
        street: billing?.street,
        zipCode: billing?.zipCode,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={billingValidationSchema(intl)}
    >
      {(props) => (
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={props.handleSubmit}>
            <div>
              <Label htmlFor="pricingPlan" value={intl.formatMessage({ id: 'pages.billing.labels.plan', defaultMessage: 'Pricing plan' })} />
              <div className="mt-2">
                <div>
                  <Select
                    {...props.getFieldProps('pricingPlan')}
                    disabled
                    helperText={
                      <FormattedMessage
                        id={'pages.billing.buttons.change_plan'}
                        defaultMessage={'<link>Change my plan</link>'}
                        values={{
                          link: (chunks) => (
                            <Link to={url} target="_blank" rel="noopener noreferrer" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                              {chunks}
                            </Link>
                          ),
                        }}
                      />
                    }
                  >
                    <option value={PricingPlan.Basic}>
                      <FormattedMessage id="common.pricing_plans.basic" defaultMessage="Basic plan" />
                    </option>
                    <option value={PricingPlan.Essential}>
                      <FormattedMessage id="common.pricing_plans.essential" defaultMessage="Essential plan" />
                    </option>
                    <option value={PricingPlan.Premium}>
                      <FormattedMessage id="common.pricing_plans.premium" defaultMessage="Premium plan" />
                    </option>
                  </Select>
                </div>
              </div>
            </div>
            {billing?.company && (
              <div>
                <Label htmlFor="company" value={intl.formatMessage({ id: 'pages.billing.labels.company', defaultMessage: 'Company' })} />
                <div className="mt-2">
                  <TextInput
                    id="company"
                    type="text"
                    autoComplete="billing company"
                    placeholder={intl.formatMessage({
                      id: 'pages.billing.placeholders.company',
                      defaultMessage: 'Type your company',
                    })}
                    {...props.getFieldProps('company')}
                  />
                </div>
              </div>
            )}
            <div>
              <Label htmlFor="name" value={intl.formatMessage({ id: 'pages.billing.labels.name', defaultMessage: 'First name' })} />
              <div className="mt-2">
                <ShrtFormikTextInput
                  autoComplete="billing given-name"
                  defaultPlaceholder="Type the full name of the payer"
                  formikProps={props}
                  id="name"
                  placeholderId="pages.billing.placeholders.name"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="street" value={intl.formatMessage({ id: 'pages.account.labels.street', defaultMessage: 'Street' })} />
              <div className="mt-2">
                <ShrtFormikTextInput
                  autoComplete="billing street"
                  defaultPlaceholder="11 Abbey Road"
                  formikProps={props}
                  id="street"
                  placeholderId="pages.billing.placeholders.street"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="zipCode" value={intl.formatMessage({ id: 'pages.account.labels.zip_code', defaultMessage: 'Zip code' })} />
              <div className="mt-2">
                <ShrtFormikTextInput
                  autoComplete="billing street"
                  defaultPlaceholder="Type your zip code"
                  formikProps={props}
                  id="zipCode"
                  placeholderId="pages.billing.placeholders.zip_code"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="city" value={intl.formatMessage({ id: 'pages.account.labels.city', defaultMessage: 'City' })} />
              <div className="mt-2">
                <ShrtFormikTextInput
                  autoComplete="billing city"
                  defaultPlaceholder="Type your city"
                  formikProps={props}
                  id="city"
                  placeholderId="pages.billing.placeholders.city"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="country" value={intl.formatMessage({ id: 'pages.account.labels.country', defaultMessage: 'Country' })} />
              <div className="mt-2">
                <ShrtFormikTextInput
                  autoComplete="billing country"
                  defaultPlaceholder="Type your country"
                  formikProps={props}
                  id="country"
                  placeholderId="pages.billing.placeholders.country"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-10">
              <Button type="reset" color="gray" outline>
                <HiX className="mr-2 h-5 w-5" />
                <FormattedMessage id="pages.billing.buttons.cancel_plan" defaultMessage="Cancel" />
              </Button>
              <Button type="submit" outline disabled={props.isSubmitting}>
                <HiCheck className="mr-2 h-5 w-5" />
                <FormattedMessage
                  id={props.isSubmitting ? 'pages.billing.buttons.updating_information' : 'pages.billing.buttons.update_information'}
                  defaultMessage={props.isSubmitting ? 'Saving...' : 'Save'}
                />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}
