import { Button, Label } from 'flowbite-react';
import { Formik } from 'formik';
import { useState } from 'react';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import ShrtFormikTextInput from '../../../components/common/ShrtFormikTextInput';
import { ConfirmSignUpParams, handleResendSignUpCode } from '../../../services/authentication.service';
import { signUpCodeValidationSchema } from './sign_up_code_validation';

type ConfirmSignUpModalState = {
  code: string;
  sendingCode: boolean;
};

type ConfirmSignUpModalProps = {
  cancel: () => void;
  confirm: (input: ConfirmSignUpParams) => void;
  email: string;
};

export default function ConfirmSignUpModal({ cancel, confirm, email }: ConfirmSignUpModalProps) {
  const intl = useIntl();
  const [state, setState] = useState<ConfirmSignUpModalState>({
    code: '',
    sendingCode: false,
  });

  const handleOnSendAnotherCode = async () => {
    setState({ ...state, sendingCode: true });
    await handleResendSignUpCode(email);
    setState({ ...state, sendingCode: false });
  };

  const handleOnSubmit = (values: { code: string }) => {
    return confirm({ code: values.code, username: email });
  };

  return (
    <Formik initialValues={{ code: '' }} onSubmit={handleOnSubmit} validationSchema={signUpCodeValidationSchema(intl)}>
      {(props) => (
        <form className="space-y-6" onSubmit={props.handleSubmit}>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="code" value="Code" />
            </div>
            <ShrtFormikTextInput
              autoComplete="off"
              defaultPlaceholder="Enter the code received by email"
              formikProps={props}
              id="code"
              placeholderId="pages.sign_up.modal.placeholders.code"
            />
          </div>
          <div className="flex justify-center gap-4">
            <Button color="gray" onClick={handleOnSendAnotherCode} disabled={state.sendingCode}>
              <HiX className="mr-2 h-5 w-5" />
              <FormattedMessage
                id={state.sendingCode ? 'pages.sign_up.modal.buttons.sending_another_code' : 'pages.sign_up.modal.buttons.send_another_code'}
                defaultMessage={state.sendingCode ? 'Sending...' : 'Send another code'}
              />
            </Button>
            <Button color="success" type="submit" outline>
              <HiCheck className="mr-2 h-5 w-5" />
              <FormattedMessage id="pages.sign_up.modal.buttons.confirm" defaultMessage="Complete sign up" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
