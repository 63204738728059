import { ConsoleLogger } from 'aws-amplify/utils';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { Formik } from 'formik';
import { useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_no_background.png';
import { Route } from '../../config/routes';
import { ConfirmSignUpParams, handleConfirmSignUp, handleSignUp } from '../../services/authentication.service';
import ConfirmSignUpModal from './modal/modal';
import { signUpValidationSchema } from './sign-up_validation';

type Credentials = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
};

const logger = new ConsoleLogger('ShrtSignUp', process.env.REACT_APP_LOG_LEVEL);

export default function ShrtSignUp() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseConfirmSignUpModal = () => {
    setOpenModal(false);
  };

  const handleOnCancel = () => {
    setOpenModal(false);
  };

  const handleOnConfirm = async (input: ConfirmSignUpParams) => {
    try {
      const response = await handleConfirmSignUp(input);
      logger.debug('confirmSignUp response', response);
      if (response.isSignUpComplete) {
        setOpenModal(false);
        navigate(Route.Home);
      } else {
        // TODO check next step and display alerts
      }
    } catch (error) {
      logger.error('Error confirming sign up', error);
    }
  };

  const handleOnSignUp = async (values: Credentials) => {
    const { isSignUpComplete, nextStep } = await handleSignUp({
      ...values,
      email: values.email.trim().toLowerCase(),
    });
    if (!isSignUpComplete) {
      if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
        setOpenModal(true);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '', confirmPassword: '', firstName: '', lastName: '' }}
        onSubmit={handleOnSignUp}
        validationSchema={signUpValidationSchema(intl)}
      >
        {(props) => (
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img className="mx-auto h-10 w-auto" src={logo} alt="shortenify" />
              {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                <FormattedMessage id="pages.sign_up.title" defaultMessage="Register" />
              </h2> */}
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={props.handleSubmit}>
                <div>
                  <Label htmlFor="email" value={intl.formatMessage({ id: 'pages.sign_up.labels.email', defaultMessage: 'Email:' })} />
                  <div className="mt-2">
                    <TextInput
                      id="email"
                      type="email"
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        id: 'pages.sign_up.placeholders.email',
                        defaultMessage: 'Type your email',
                      })}
                      color={props.touched.email && props.errors.email ? 'failure' : undefined}
                      helperText={props.touched.email && props.errors.email ? <span className="font-medium">{props.errors.email}</span> : null}
                      {...props.getFieldProps('email')}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="firstName" value={intl.formatMessage({ id: 'pages.sign_up.labels.first_name', defaultMessage: 'First name' })} />
                  <div className="mt-2">
                    <TextInput
                      id="firstName"
                      type="text"
                      autoComplete="billing given-name"
                      placeholder={intl.formatMessage({
                        id: 'pages.sign_up.placeholders.first_name',
                        defaultMessage: 'Type your first name',
                      })}
                      color={props.touched.firstName && props.errors.firstName ? 'failure' : undefined}
                      helperText={props.touched.firstName && props.errors.firstName ? <span className="font-medium">{props.errors.firstName}</span> : null}
                      {...props.getFieldProps('firstName')}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="lastName" value={intl.formatMessage({ id: 'pages.sign_up.labels.last_name', defaultMessage: 'Last name' })} />
                  <div className="mt-2">
                    <TextInput
                      id="lastName"
                      type="text"
                      autoComplete="billing family-name"
                      placeholder={intl.formatMessage({
                        id: 'pages.sign_up.placeholders.last_name',
                        defaultMessage: 'Type your last name',
                      })}
                      color={props.touched.lastName && props.errors.lastName ? 'failure' : undefined}
                      helperText={props.touched.lastName && props.errors.lastName ? <span className="font-medium">{props.errors.lastName}</span> : null}
                      {...props.getFieldProps('lastName')}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="password" value={intl.formatMessage({ id: 'pages.sign_up.labels.password', defaultMessage: 'Password' })} />
                  <div className="mt-2">
                    <TextInput
                      id="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder={intl.formatMessage({
                        id: 'pages.sign_up.placeholders.password',
                        defaultMessage: 'Type your password',
                      })}
                      color={props.touched.password && props.errors.password ? 'failure' : undefined}
                      helperText={props.touched.password && props.errors.password ? <span className="font-medium">{props.errors.password}</span> : null}
                      {...props.getFieldProps('password')}
                    />
                  </div>
                </div>
                <div>
                  <Label
                    htmlFor="confirmPassword"
                    value={intl.formatMessage({ id: 'pages.sign_up.labels.confirm_password', defaultMessage: 'Confirm password' })}
                  />
                  <div className="mt-2">
                    <TextInput
                      id="confirmPassword"
                      type="password"
                      autoComplete="current-password"
                      placeholder={intl.formatMessage({
                        id: 'pages.sign_up.placeholders.confirm_password',
                        defaultMessage: 'Same password as above',
                      })}
                      color={props.touched.confirmPassword && props.errors.confirmPassword ? 'failure' : undefined}
                      helperText={
                        props.touched.confirmPassword && props.errors.confirmPassword ? (
                          <span className="font-medium">{props.errors.confirmPassword}</span>
                        ) : null
                      }
                      {...props.getFieldProps('confirmPassword')}
                    />
                  </div>
                </div>
                <div>
                  <Button className="flex w-full" type="submit" outline disabled={props.isSubmitting}>
                    <HiCheck className="mr-2 h-5 w-5" />
                    <FormattedMessage id="pages.sign_up.buttons.confirm" defaultMessage="Sign up" />
                  </Button>
                </div>
              </form>
            </div>

            <Modal show={openModal} size="xl" onClose={handleCloseConfirmSignUpModal} popup>
              <Modal.Header>
                <FormattedMessage id="pages.sign_up.modal.title" defaultMessage="Email confirmation" />
              </Modal.Header>
              <Modal.Body>
                <ConfirmSignUpModal cancel={handleOnCancel} confirm={handleOnConfirm} email={props.values.email} />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </Formik>
    </>
  );
}
