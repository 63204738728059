import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const signUpCodeValidationSchema = (intl: IntlShape) =>
  Yup.object({
    code: Yup.string()
      .trim()
      .matches(
        /^\d{6}$/,
        intl.formatMessage({
          id: 'common.forms.validation.code',
          defaultMessage: 'Not a valid code',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      ),
  });
